.background {
  width: 100%;
  height: auto;
  position: relative;
}
.blueRectangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -30%);
  background-color: #000154ca;
  width: 80%;
  height: 100px;
  z-index: 1;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #ffffff;
  border-radius: 1px;
}

.rectangleText {
  font-size: 25px;
  font-weight: bold;
}
.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: #ffffff;
}
.links {
  display: flex;
  padding: 10px !important;
  justify-content: space-between;
  width: 70%;
}
.menuItem {
  position: relative;
}
.navLink {
  padding: 10px 20px;
  text-decoration: none;
  color: #000000 !important;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
}

.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #333;
  padding: 10px;
  width: 200px;
  font-size: 15px;
  text-align: left;
  border-radius: 5px;
  z-index: 4;
}

.submenu a {
  display: block;
  padding: 10px;
  color: #fff;
  text-decoration: none;
}

.submenu a:hover {
  background-color: #555;
}

.menuItem:hover .submenu {
  display: block;
}

.drawer {
  display: none;
}

.drawerOpen {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #333;
  width: 100%;
  padding: 20px;
}

.menuButton {
  display: none;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px 42px;
  text-align: center;
}
.menuButton {
  font-size: 28px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  display: none;
  z-index: 4;
}

.drawer {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 1, 84, 0.95);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  transition: right 0.3s ease;
  color: #fff;
}

.logo {
  aspect-ratio: 1.7;
  object-fit: contain;
  width: 200px;
  max-width: 100%;
}

.navLink {
  flex-grow: 1;
  margin: auto 0;
  color: #fff;
  text-decoration: none;
}

.heroContent {
  border-radius: 18px;
  background-color: #da0b19;
  max-width: 595px;
  margin: 230px 0 -40px 95px;
  padding: 57px 44px 27px;
}

.heroTitle {
  font-size: 38px;
  margin: 0;
}

.heroDescription {
  margin-top: 27px;
}

.ctaButton {
  border-radius: 6px;
  margin-top: 54px;
  padding: 17px 17px;
  border: 3px solid #fff;
  background: none;
  color: #fff;
  font: inherit;
  cursor: pointer;
}
.ctaButton:hover {
  border-radius: 6px;
  margin-top: 54px;
  padding: 17px 17px;
  background-color: #000154;
  color: #fff;
  font: inherit;
  cursor: pointer;
}


@media (max-width: 991px) {

  .menuButton {
    font-size: 28px;
    background: none;
    border: solid #000000 1px;
    margin: 5px;
    color: #000000;
    cursor: pointer;
    display: block;
  }
  .blueRectangle {
    position: absolute;
    top: 18%;
    left: 50%;
    transform: translate(-50%, -10%);
    background-color: #000154ca;
    width: 80%;
    height: 40px;
    z-index: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #ffffff;
    border-radius: 1px;
  }
  
  .rectangleText {
    font-size: 15px;
    font-weight: bold;
  }
  .navigation {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .logo {
    aspect-ratio: 1.7;
    object-fit: contain;
    width: 100px;
    max-width: 100%;
  }
  
  .navLink {
    display: none;
  }
  
  .heroContent {
    border-radius: 5px;
    background-color: #da0b19;
    max-width: 200px;
    max-height: 200px;
    margin: 30px 0 50px 20px;
    padding: 10px 10px 10px;
  }
  
  .heroTitle {
    font-size: 15px;
    margin: 0;
  }
  
  .heroDescription {
    margin-top: 10px;
  }
  
  .ctaButton {
    border-radius: 3px;
    margin-top: 1px;
    padding: 3px 3px;
    border: 1px solid #fff;
    background: none;
    color: #fff;
    font-size: 10px;
    cursor: pointer;
  }
}
