.ListImages {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  justify-content: space-between;
}
.LImage {
  width: auto;
  height: 50px;
  margin-top: 30px;
}

@media (max-width: 991px) {
  .ListImages {
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    justify-content: space-between;
  }
  .LImage {
    width: 20%;
    height: auto;
  }
}
