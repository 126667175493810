// ManagementTeam.module.scss
.managementTeam {
    padding: 50px 0;
    background-color: #f9f9f9;
    text-align: center;
  
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }
  
    .title {
      font-size: 2.5rem;
      color: #141e50;
      margin-bottom: 20px;
    }
  
    .description {
      font-size: 1.2rem;
      color: #333;
      margin-bottom: 40px;
    }
  
    .teamGrid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 30px;
    }
  
    .teamMember {
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
  
      .memberImage {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 15px;
      }
  
      .memberName {
        font-size: 1.5rem;
        color: #141e50;
        margin-bottom: 10px;
      }
  
      .memberRole {
        font-size: 1.1rem;
        color: #666;
        margin-bottom: 10px;
      }
  
      .memberBio {
        font-size: 1rem;
        color: #333;
      }
    }
  }
  
  // Responsive Design
  @media (max-width: 768px) {
    .managementTeam {
      padding: 30px 0;
  
      .title {
        font-size: 2rem;
      }
  
      .description {
        font-size: 1rem;
      }
  
      .teamGrid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
  
      .teamMember {
        padding: 15px;
  
        .memberImage {
          width: 120px;
          height: 120px;
        }
  
        .memberName {
          font-size: 1.2rem;
        }
  
        .memberRole {
          font-size: 1rem;
        }
  
        .memberBio {
          font-size: 0.9rem;
        }
      }
    }
  }