.faqSection {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    color: #000154;
    padding: 60px 93px;
    font: 400 20px Jacques Francois, -apple-system, Roboto, Helvetica, sans-serif;
    .sectionTitle {
      font-size: 40px;
      font-weight: 900;
      text-align: center;
      margin: 0;
    }
    .sectionDescription {
      color: rgba(0, 1, 84, 0.54);
      font-size: 25px;
      letter-spacing: 1.2px;
      text-align: center;
      margin-top: 20px;
    }
    .faqList {
      margin-top: 40px;
    }
    .faqItem {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      margin-bottom: 40px;
      margin-top: 20px;
      border-bottom: #0000005e solid 0.6px;
    }
    .faqQuestion {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      letter-spacing: 0.96px;
      margin-bottom: 20px;
    }
  
    .faqIcon {
      aspect-ratio: 1;
      object-fit: contain;
      width: 96px;
    }
  
    .faqAnswer {
      margin-top: 6px;
      padding-top: 6px;
      border-top: 1px solid rgba(0, 1, 84, 0.54);
    }
  
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 0 20px;
  
      .sectionTitle {
        margin-top: 40px;
        font-size: 40px;
      }
  
      .sectionDescription {
        max-width: 100%;
        margin-top: 20px;
      }
  
      .faqList {
        margin-top: 20px;
      }
  
      .faqQuestion {
        max-width: 100%;
      }
    }
  }
  