.downloadsPart {
  margin-top: 0px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}
.image {
  width: 100%;
  height: auto;
}

.Rectangle {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 1, 84, 0.6); 
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: #ffffff;
  border-radius: 1px;
}

.rectangleText {
  padding-left: 100px;
  padding-top: 40px;
  line-height: 30px;
  width: 60%;
  font-size: 15px;
  font-weight: 900;
}

@media (max-width: 991px) { 
  .downloadsPart {
    margin-top: 0px;
    width: 100%;
    position: relative;
  }
  .image {
    width: 100%;
    height: auto;
  }
  
  .Rectangle {
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 1, 84, 0.6); 
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    color: #ffffff;
    border-radius: 1px;
  }
  
  .rectangleText {
    line-height: inherit;
    text-align: center;
    width: 100%;
    font-size: 10px;
    font-weight: 500;
  }
}