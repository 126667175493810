.container {
  position: relative;
  width: 100%;
  max-width: 90%;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
.image {
  width: 100%;
  border-radius: 20px;
  height: auto;
}

.textContainer {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #fff;
  text-align: left;
}

.title {
  font-size: 2rem;
  margin-left: 60px;
  margin-top: 60px;
}
.button {
  margin-left: 60px;
  margin-top: 60px;
  background-color: #ffffff;
  color: #000154;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.buttonText {
  font-weight: 900;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.button:hover {
  background-color: #da0b19;
  color: #ffffff;
}

  
  @media (max-width: 991px) {
    .container {
      position: relative;
      width: 100%;
      max-width: 90%;
      border-radius: 5px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    .image {
      width: 100%;
      border-radius: 5px;
      height: auto;
    }
    
    .textContainer {
      position: absolute;
      top: 10px;
      left: 20px;
      color: #fff;
      text-align: left;
    }
    
    .title {
      font-size: 15px;
      margin-left: 10px;
      margin-top: 10px;
    }
    .button {
      margin-left: 10px;
      margin-top: 10px;
      background-color: #ffffff;
      color: #000154;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    .buttonText {
      font-weight: 900;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    
    .button:hover {
      background-color: #da0b19;
      color: #ffffff;
    }
  }