.footer {
  background-color: #f3f3ff !important;
  padding-top: 40px;
}
.logo {
  aspect-ratio: 1.7;
  object-fit: contain;
  width: 150px;
  max-width: 100%;
}
.tagline {
  color: #000154;
  font-weight: 700;
}
.socialIcons {
  justify-content: space-between;
  
  i {
    color: #000154;
    padding: 10px;
  }
}
.listTitle {
  padding-left: 20px;
  font-weight: 700;
}
.list {
  list-style: none;
  padding: 2px;
}
.list:hover {
  color: #000154;
  cursor: pointer;
}
.anchor {
  font-weight: 500;
  color: #6d767e;
  text-decoration: none;
}
.copyright {
  color: #000;
  font-weight: 700;
}
.designed {
  color: #000;
  font-weight: 700;
}