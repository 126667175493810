.services {
  background-color: #fff;
  font-family: Jacques Francois, -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 400;
  padding: 84px 30px 45px;
}

.sectionTitle {
  color: #000154;
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  margin: 0;
}

.sectionDescription {
  color: rgba(0, 1, 84, 0.54);
  font-size: 25px;
  width: 900px;
  letter-spacing: 1.2px;
  text-align: center;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}

.servicesList {
  display: flex;
  flex-direction: column;
  gap: 56px;
  margin-top: 56px;

  .serviceItem {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    gap: 16px;
    width: 80%;
    height: 180px;

    .serviceNumber {
      border-radius: 15px;
      background-color: #000154;
      font-size: 36px;
      color: #fff;
      text-align: center;
      letter-spacing: 2.88px;
      padding: 15px 35px 22px 35px;
    }

    .serviceContent {
      border-radius: 20px;
      background-color: #ddddef;
      flex-grow: 1;
      padding: 17px 80px 42px;

      .serviceTitle {
        color: #000154;
        font-size: 26px;
        letter-spacing: 1.08px;
        text-align: left;
        margin: 0 0 28px;
      }

      .serviceDescription {
        color: rgba(0, 1, 84, 0.54);
        font-size: 18px;
        letter-spacing: 0.72px;
      }
    }
  }
}

@media (max-width: 991px) {
  .services {
    background-color: #fff;
    font-family: Jacques Francois, -apple-system, Roboto, Helvetica, sans-serif;
    font-weight: 400;
    padding: 40px 0px 45px 0px;
  }
  
  .sectionTitle {
    color: #000154;
    font-size: 30px;
    text-align: center;
    margin: 0;
  }
  
  .sectionDescription {
    color: rgba(0, 1, 84, 0.54);
    font-size: 15px;
    width: 300px;
    letter-spacing: 1.2px;
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .servicesList {
    display: flex;
    flex-direction: column;
    gap: 56px;
    margin-top: 30px;
  
    .serviceItem {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      gap: 10px;
      width: 80%;
      height: 250px;
  
      .serviceNumber {
        border-radius: 10px;
        background-color: #000154;
        font-size: 16px;
        color: #fff;
        text-align: center;
        letter-spacing: 2.88px;
        padding: 10px 10px 0px 10px;
        height: 40px;
      }
  
      .serviceContent {
        border-radius: 20px;
        background-color: #ddddef;
        flex-grow: 1;
        padding: 17px 10px 42px;
  
        .serviceTitle {
          color: #000154;
          font-size: 16px;
          letter-spacing: 1.08px;
          text-align: left;
          margin: 0;
        }
  
        .serviceDescription {
          color: rgba(0, 1, 84, 0.54);
          font-size: 15px;
          letter-spacing: 0.72px;
        }
      }
    }
  }
  }
