.documentation {
  display: block;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  position: relative;

  .textPart {
    width: 100%;
    text-align: center; // Center align text for consistency
  }

  .header {
    font-size: 30px;
    color: #141e50;
    font-weight: 700;
  }

  .subText {
    font-size: 20px;
    color: #000000;
    font-weight: 200;
  }
}

.downloadsPart {
  margin-top: 50px;
  width: 80%; // Match the width of .documentation
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .image {
    width: 100%;
    height: 200px;
  }

  .Rectangle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 1, 84, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; // Center align text
    color: #ffffff;

    .header {
      padding-top: 0; // Remove padding-top to align with .documentation
      font-size: 30px;
      color: #fff;
      font-weight: 700;
    }

    .rectangleText {
      line-height: 30px;
      width: 60%;
      font-size: 15px;
      font-weight: 900;
    }
  }
}

.ListImages {
  display: flex;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  justify-content: space-between;

  .LImage {
    width: 20%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .documentation {
    width: 100%;

    .header {
      font-size: 20px;
    }

    .subText {
      font-size: 10px;
    }
  }

  .downloadsPart {
    width: 100%;

    .image {
      height: auto;
    }

    .Rectangle {
      .header {
        font-size: 20px;
      }

      .rectangleText {
        font-size: 10px;
        width: 80%; // Adjust width for smaller screens
      }
    }
  }

  .ListImages {
    width: 100%;

    .LImage {
      width: 20%;
    }
  }
}