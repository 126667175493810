.about {
  background-color: #f3f3ff;
  padding-bottom: 81px;
}
.statsContainer {
  border-radius: 25px;
  background-color: #da0b19;
  display: flex;
  margin: -82px auto 0;
  width: 1304px;
  max-width: 100%;
  justify-content: space-between;
  padding: 30px 20px 20px 20px;
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.vl {
  border-left: 2px solid #ffffff;
  height: 50px;
}
.statItem {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}
.statLabel {
  font-size: 18px;
}
.statValue {
  font-size: 30px;
  margin-top: 15px;
  font-weight: 700;
}

.contentContainer {
  display: flex;
  margin-top: 202px;
  padding: 0 40px;

  .textContent {
    padding: 0 0 0 10px;
    width: 40%;
    color: rgba(0, 1, 84, 0.54);
    font-size: 25px;
    font-weight: 400;

    .title {
      color: #000154;
      font-size: 40px;
      font-weight: 900;
      margin: 0;
    }

    .description {
      letter-spacing: 0.96px;
      margin-top: 56px;
    }

    .trustedBy {
      letter-spacing: 1.92px;
      margin-top: 36px;
    }
  }
  .trustedBy1 {
    display: none;
  }
  .image {
    aspect-ratio: 1.58;
    object-fit: contain;
    width: 50%;
    border-radius: 10px;
    margin: 0 0 0 100px;
  }
}

.partnersLogo {
  aspect-ratio: 10.31;
  object-fit: contain;
  width: 500px;
  padding-left: 40px;
  max-width: 100%;
}

@media (max-width: 991px) {
  .about {
    background-color: #f3f3ff;
    padding-bottom: 81px;
  }
  // .statsContainer {
  //   border-radius: 10px;
  //   background-color: #da0b19;
  //   display: flex;
  //   margin: -32px auto 0;
  //   width: 300px;
  //   max-width: 100%;
  //   justify-content: space-between;
  //   padding: 20px 10px 10px 10px;
  //   color: #fff;
  //   text-align: center;
  //   font-size: 32px;
  //   font-weight: 400;
  //   position: relative;
  //   z-index: 10;
  // }

  .statsContainer {
    border-radius: 10px;
    background-color: #da0b19;
    display: flex;
    margin: -32px auto 0;
    width: 300px;
    max-width: 100%;
    justify-content: space-between;
    padding: 20px 10px 10px 10px;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
  }
  
  .vl {
    border-left: 1px solid #ffffff;
    height: 30px;
  }
  .statItem {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }
  .statLabel {
    font-size: 10px;
  }
  .statValue {
    font-size: 15px;
    margin-top: 10px;
    font-weight: 700;
  }

  .contentContainer {
    display: block;
    margin-top: 50px;
    padding: 0 20px 0 10px;

    .textContent {
      width: 100%;
      color: rgba(0, 1, 84, 0.54);
      font-size: 18px;
      font-weight: 400;

      .title {
        color: #000154;
        font-size: 30px;
        font-weight: 900;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
      .description {
        letter-spacing: 0.96px;
        margin-top: 16px;
        text-align: center;
      }
      .trustedBy {
        letter-spacing: 1.92px;
        display: none;
      }
    }
    .trustedBy1 {
      letter-spacing: 1.92px;
      font-size: 18px;
      color: rgba(0, 1, 84, 0.54);
    }
    .image {
      aspect-ratio: 1.58;
      object-fit: contain;
      width: 100%;
      border-radius: 5px;
      margin: auto 0;
    }
  }
  .partnersLogo {
    aspect-ratio: 10.31;
    object-fit: contain;
    width: 200px;
    margin-top: 45px;
    max-width: 100%;
  }
}
