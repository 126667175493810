// OurValues.module.scss
.ourValues {
    padding: 50px 0;
    background-color: #f9f9f9;
    text-align: center;
  
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;
    }
  
    .title {
      font-size: 2.5rem;
      color: #141e50;
      margin-bottom: 20px;
    }
  
    .description {
      font-size: 1.2rem;
      color: #333;
      margin-bottom: 40px;
    }
  
    .valuesGrid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 20px;
    }
  
    .valueCard {
      background-color: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
  
      .valueTitle {
        font-size: 1.5rem;
        color: #141e50;
        margin-bottom: 10px;
      }
  
      .valueDescription {
        font-size: 1rem;
        color: #666;
      }
    }
  }
  
  // Responsive Design
  @media (max-width: 768px) {
    .ourValues {
      padding: 30px 0;
  
      .title {
        font-size: 2rem;
      }
  
      .description {
        font-size: 1rem;
      }
  
      .valuesGrid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
  
      .valueCard {
        padding: 15px;
  
        .valueTitle {
          font-size: 1.2rem;
        }
  
        .valueDescription {
          font-size: 0.9rem;
        }
      }
    }
  }