.newsSection {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 18px;
  margin-top: 55px;
}
.sectionTitle {
  color: #000154;
  font: 900 40px Jacques Francois, -apple-system, Roboto, Helvetica, sans-serif;
  margin: 0;
}
.sectionDescription {
  color: rgba(0, 1, 84, 0.54);
  letter-spacing: 1.2px;
  text-align: center;
  margin-top: 20px;
  font: 400 25px Jacques Francois, -apple-system, Roboto, Helvetica, sans-serif;
}
.newsGrid {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
  max-width: 90%;
}
.newsItem {
  flex: 0 0 calc((100% - 400px) / 3);
  border-radius: 25px;
  background-color: #ddddef;
  display: flex;
  flex-direction: column;
  align-items: start;
  color: rgba(0, 1, 84, 0.54);
  letter-spacing: 0.72px;
  padding: 10px;
  font: 400 18px Jacques Francois, -apple-system, Roboto, Helvetica, sans-serif;
  scroll-snap-align: start;
}
.newsImage {
  object-fit: contain;
  width: 100%;
  border-radius: 10px;
}
.newsDate {
  text-align: center;
  margin-top: 18px;
}
.newsTitle {
  color: #000154;
  font-size: 20px;
  letter-spacing: 0.96px;
  margin-top: 16px;
}
.newsExcerpt {
  margin-top: 5px;
}
.readMore {
  border-radius: 10px;
  background-color: #000154;
  margin-top: 5px;
  width: 130px;
  max-width: 100%;
  color: #fff;
  font-weight: 900;
  padding: 15px 15px 15px 15px;
  text-decoration: none;
}
.readAllNews {
  display: flex;
  margin-top: 43px;
  align-items: center;
  gap: 27px;
  color: #000154;
  text-align: center;
  letter-spacing: 1.08px;
  font: 900 20px Jacques Francois, -apple-system, Roboto, Helvetica, sans-serif;
}
.readAllLink {
  color: inherit;
  text-decoration: none;
}

.arrowIcon {
  aspect-ratio: 1.5;
  object-fit: contain;
  width: 60px;
}

@media (max-width: 991px) {
  .newsSection {
    max-width: 100%;
    margin-top: 40px;
  }

  .sectionTitle {
    font-size: 40px;
  }

  .sectionDescription {
    max-width: 100%;
    margin-top: 20px;
  }

  .newsGrid {
    flex-direction: row;
    max-width: 100%;
    margin-top: 10px;
    overflow-x: auto;
  }

  .newsItem {
    flex: 0 0 80%;
    margin-top: 20px;
    padding-left: 20px;
  }

  .readMore {
    width: 130px;
    padding: 15px 15px 15px 15px;
  }

  .readAllNews {
    margin-top: 20px;
    margin-bottom: 40px;
  }
}
